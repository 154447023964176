import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('slider', function (conf = {}) {
    return {
      enabled: false,
      instance: null,
      async load() {
        const slider = this.$refs['slider'] || this.$root
        const pager = this.$refs['pager']
        const [next, prev] = [this.$refs['next'], this.$refs['prev']]

        const [Swiper, modules] = await Promise.all([
          import(/* webpackMode: "lazy-once" */ 'swiper').then(
            (mod) => mod.default
          ),
          import(/* webpackMode: "lazy-once" */ 'swiper/modules').then(
            (mod) => [mod.Navigation, mod.Pagination]
          ),
          import(/* webpackMode: "lazy-once" */ 'swiper/css'),
          import(/* webpackMode: "lazy-once" */ 'swiper/css/pagination'),
        ]).catch(e => console.error(e))

        this.instance = new Swiper(slider, {
          modules,
          ...((next && prev) && {
              navigation: {
                nextEl: next,
                prevEl: prev,
                disabledClass: 'opacity-20',
              },
            }),
          ...(pager && {
              pagination: {
                el: pager,
                clickable: true,
              },
          }),
          ...conf,
          on: {
            afterInit: (instance) => {
              this.enabled = instance.enabled
            },
            enable: () => {
              this.enabled = true
            },
            disable: () => {
              this.enabled = false
            },
            slidesUpdated: () => {
              // FIX: Swiper does not remove styles correctly
              if (!this.enabled) slider.querySelector('.swiper-wrapper').removeAttribute('style')
            },
          },
        })
      },
    }
  })
})()
