import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('video', function (config = {}, srcs = null) {
    return {
      loaded: false,
      async init() {
        const elem =
          this.$root.nodeName === 'VIDEO'
            ? this.$root
            : this.$root.querySelector('video')

        if (!elem) return

        const [_, __, videojs] = await Promise.all([
          import(/* webpackMode: "lazy-once" */ 'dashjs'),
          import(/* webpackMode: "lazy-once" */ 'videojs-contrib-dash'),
          import(/* webpackMode: "lazy-once" */ 'video.js')
            .then((mod) => mod.default)
            .catch((err) => console.error(err)),
          import(/* webpackMode: "lazy-once" */ 'video.js/dist/video-js.css'),
        ])

        /** @type {import('video.js').default} */
        const player = videojs(elem, {
          ...config,
          html5: {
            vhs: {
              overrideNative: true
            },
          }
        })

        player.ready(() => {
          if (srcs) {
            player.src(srcs)
          }
          if (config.autoplay) {
            player.play()
          }
          this.loaded = true
        })
      },
    }
  })
})()
