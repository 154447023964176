import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import resize from '@alpinejs/resize'

import 'modules/screen'
import 'modules/sizesCollection'
import 'modules/flipTheme'
import 'modules/video'
import 'modules/iframe'
import 'modules/modal'
import 'modules/slider'
import 'modules/accordion'
import 'modules/overflowAccordion'
import 'modules/marquee'
import 'modules/looper'
import 'modules/stepper'

try {
  // measure scrollbar width
  new ResizeObserver(() => {
    if (window.getComputedStyle(document.documentElement).getPropertyValue('overflow-y') !== 'hidden') {
      document.documentElement.style.setProperty(
        '--scrollbar-width',
        `${window.innerWidth - document.documentElement.clientWidth}px`
      )
    }
  }).observe(document.body)
} catch {}

if (document.getElementById('matomo-opt-out')) {
  import('modules/matomo').then((mod) => mod.default())
}

Alpine.store('app', {
  current: 'dark',
  themes: {
    dark: ['theme-default'],
    deep: ['theme-blue'],
  },
  getCurrentTheme() {
    return this.themes[this.current].join(' ')
  },
  menuOpen: false,
  toggleMenu() {
    this.menuOpen = !this.menuOpen
  },
  closeMenu() {
    this.menuOpen = false
  },
  openMenu() {
    this.menuOpen = true
  },
  setGlobalVar(name, value, async = false) {
    const set = () => {
      document.documentElement.style.setProperty(
        `--${name}`,
        value
      )
    }
    if (async) {
      requestAnimationFrame(set)
    }
    else {
      set()
    }
  },
})

Alpine.plugin(intersect)
Alpine.plugin(resize)

window.Alpine = Alpine
Alpine.start()
