import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('stepper', function (steps = 1, duration = 5000) {
    let elapsedSinceLastStep = 0
    const isOverflowing = (el) =>
      el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth
    let loaderPromise = null

    return {
      instance: null,
      currentPercentage: 0,
      currentStep: 0,
      paused: true,
      setStep(step, pause = false) {
        this.currentStep = step
        if (pause) this.stop()
      },
      async start() {
        const FrameTicker = await loaderPromise
        this.instance = new FrameTicker()
        this.paused = false
        this.instance.onTick.add((_, tickDeltaSeconds) => {
          elapsedSinceLastStep += tickDeltaSeconds
          if (elapsedSinceLastStep >= duration / 1000) {
            if (this.currentStep + 1 === steps) this.currentStep = 0
            else this.currentStep++
            elapsedSinceLastStep = 0
          }

          this.currentPercentage =
            (elapsedSinceLastStep / (duration / 1000)) * 100
        })
      },
      stop() {
        if (this.instance) {
          this.pause()
          this.currentPercentage = 0
          elapsedSinceLastStep = 0
        }
      },
      pause() {
        if (this.instance && !this.paused) {
          this.instance.pause()
          this.paused = true
        }
      },
      resume() {
        if (this.instance && this.paused) {
          this.instance.resume()
          this.paused = false
        }
      },
      scrollToThis(target) {
        if (target && isOverflowing(this.$root)) {
          //elToScroll.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
          // smooth scroll this.$root to elToScroll
          const scrollable = this.$root
          const targetRect = target.getBoundingClientRect()
          const scrollableRect = scrollable.getBoundingClientRect()
          const targetOffsetLeft = targetRect.left - scrollableRect.left
          const targetCenterX = targetOffsetLeft + target.offsetWidth / 2
          const scrollableCenterX = scrollableRect.width / 2
          const scrollX = targetCenterX - scrollableCenterX
          scrollable.scrollTo({
            left: scrollable.scrollLeft + scrollX,
            behavior: 'smooth',
          })
        }
      },
      init() {
        loaderPromise = import('frame-ticker')
          .then((module) => module.default)
          .catch((e) => console.error(e))
      },
    }
  })
})()
