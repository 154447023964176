import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('overflowAccordion', function () {
    return {
      open: false,
      overflowing: false,
      height: 0,
      toggle() {
        this.open = !this.open
      },
      init() {
        let root = this.$refs.target || this.$root
        let measure = this.$refs.measure
        try {
          new ResizeObserver(() => {
            this.height = measure.scrollHeight
            this.overflowing = root.offsetHeight !== this.height
          }).observe(measure)
        } catch {}
      },
    }
  })
})()
