import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('iframe', function () {
    return {
      init() {
        const iframe = this.$root
        function isCalendlyEvent(e) {
          return e.data.event && e.data.event.indexOf('calendly') === 0
        }

        window.addEventListener('message', function (e) {
          if (isCalendlyEvent(e)) {
            if (e.data?.payload?.height) iframe.style.height = e.data.payload.height
          }
        })
      },
    }
  })
})()
