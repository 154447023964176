import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('accordionController', function() {
    return {
      openAccordion: null,
      activatedOnce: false,
      init() {
        if (window.location.hash.substring(1).length && this.$el.querySelector(window.location.hash)) {
          this.openAccordion = window.location.hash
        }
      }
    }
  })
  
  Alpine.data('accordion', function (initial = false) {
    const accordion = this.$el // needed because $el is context dependant
    const id = accordion.id
    const getHeight = () => this.$refs.child.scrollHeight
  
    return {
      open: initial,
      allowOverflow: initial,
      height: getHeight(),
      setHash() {
        // only needs to be called on transitionend in the markup
        if (window.location.hash.substring(1) === id) return
        history.pushState({}, '', '#' + id)
      },
      toggle(_, silent = false) {
        if (!silent && !this.activatedOnce) this.activatedOnce = true
        if (!silent && this.openAccordion && document.querySelector(this.openAccordion) === accordion) return
        if (this.open) this.allowOverflow = false
        else this.openAccordion = '#' + id
  
        this.open = !this.open
      },
      afterTransition(e) {
        if (e.propertyName !== 'height') return
        this.allowOverflow = this.open
        // hash should only change when opened
        // and not on first visit
        if (this.open && this.activatedOnce) this.setHash()
      },
      init() {
        const currentAccordion = this.openAccordion && document.querySelector(this.openAccordion)
        accordion._toggle = () => this.toggle() // for external calls
  
        switch (true) {
          case (initial && !currentAccordion):
            // initially open
            // and no other accordion is marked for toggeling
            this.openAccordion = '#' + id
            break;
  
          case (!initial && currentAccordion === accordion):
            // itinially closed but activated externally
            this.toggle(null, true)
            break;
          
          case (initial && currentAccordion !== accordion):
            this.allowOverflow = false
            this.open = false
            break;
  
          default:
            break;
        }
  
        // listen to size changes
        try {
          new ResizeObserver(() => {
            const newHeight = getHeight()
            if (this.height !== newHeight) this.height = newHeight
          }).observe(this.$refs.child)
        } catch (error) {
          // no resize observer possible
        }
  
        this.$watch('openAccordion', (current) => {
          if (this.open && current && document.querySelector(current) !== accordion) {
            this.allowOverflow = false
            this.open = false
          }
        })
      }
    }
  })
})()