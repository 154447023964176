import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('marquee', (conf) => ({
    instance: null,
    async init() {
      const [Plugin, loop] = await import('dynamic-marquee').then(mod => [mod.Marquee, mod.loop]).catch(e => console.log(e))

      const { random } = conf
      if (random !== undefined) {
        delete conf.random
      }
      this.instance = new Plugin(this.$refs.target, conf)

      const items = [...this.$refs.source.children].map(el => {
        const html = el.outerHTML
        el.style.opacity = 0
        return html
      })
      const el = document.createElement('div')
      const builders = items.map(html => () => {
        el.innerHTML = html.trim()
        const out = el.firstElementChild
        return out
      })
      if (random) {
        builders.sort(() => Math.random() - 0.5)
      }

      loop(this.instance, builders)
    },
    pause() {
      this.instance.setRate(0)
    },
    resume() {
      this.instance.setRate(conf.rate)
    },
    destroy() {
      this.instance && this.instance.clear()
    },
  }))
})()