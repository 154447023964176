import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('looper', function (steps = 1, duration = 5000) {
    let elapsedSinceLastStep = 0

    return {
      instance: null,
      currentStep: 0,
      async init() {
        const FrameTicker = await import('frame-ticker')
          .then((module) => module.default)
          .catch((e) => console.error(e))
        this.instance = new FrameTicker()
        this.instance.onTick.add((_, tickDeltaSeconds) => {
          elapsedSinceLastStep += tickDeltaSeconds
          if (elapsedSinceLastStep >= duration / 1000) {
            if (this.currentStep + 1 === steps) this.currentStep = 0
            else this.currentStep++
            elapsedSinceLastStep = 0
          }
        })
      },
    }
  })
})()
