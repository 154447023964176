import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('flipTheme', function (theme) {
    let lastTheme = null
    return {
      activate() {
        lastTheme = this.$store.app.current
        this.$store.app.current = theme
      },
      deactivate() {
        if (lastTheme) {
          this.$store.app.current = lastTheme
        }
      },
    }
  })
})()
